const menu = [
  { url: "/api/", title: "Getting Started" },
  { url: "/api/play-sound/", title: "Play Sound" },
  { url: "/api/play-clip/", title: "Play Clip" },
  { url: "/api/play-text/", title: "Play Text" },
  { url: "/api/play-url/", title: "Play URL" },
  { url: "/api/play-file/", title: "Play File" },
  { url: "/api/list-sounds/", title: "List Sounds" },
  { url: "/api/list-clips/", title: "List Clips" },
  { url: "/api/list-voices/", title: "List Voices" },
  { url: "/api/list-targets/", title: "List Targets" },
  { url: "/api/response-codes/", title: "Response Codes" },
]

export default menu
