import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import apiMenu from "./api-menu"

const Title = styled.h2`
  margin-top: 0;
  font-size: 19px;
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  margin-bottom: 0;
  list-style-type: none;
`
const ListItem = styled.li``

const ListItemLink = styled(Link)`
  position: relative;

  &:before {
    display: block;
    content: "";
    position: absolute;
    top: 3px;
    left: -12px;
    width: 2px;
    height: 16px;
    background-color: #eee;
    transition: background-color 100ms ease-in-out;
  }

  &:hover {
    text-decoration: none;
    &:before {
      background-color: #ccc;
    }
  }

  &.active {
    font-weight: bold;

    &:before {
      display: block;
      content: "";
      position: absolute;
      top: 3px;
      left: -12px;
      width: 2px;
      height: 16px;
      background-color: #333;
    }
  }
`

const elements = apiMenu.map(item => (
  <ListItem key={item.url}>
    <ListItemLink to={item.url} activeClassName="active">
      {item.title}
    </ListItemLink>
  </ListItem>
))

const ApiSidebar = () => (
  <>
    <List>{elements}</List>
  </>
)

export default ApiSidebar
