import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import apiMenu from "./api-menu"
import { FiArrowLeft, FiArrowRight } from "react-icons/fi"
import _ from "lodash"

const ApiDocFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  a {
    display: block;
    background-color: #fb251b;
    color: antiquewhite;
    padding: 6px 12px;
    border-radius: 10px;
    display: flex;
    align-items: center;

    &.next {
      margin-left: auto;
    }

    svg {
      margin-right: 5px;
    }

    &:last-child {
      svg {
        margin-right: 0;
        margin-left: 5px;
      }
    }
  }
`

export default props => {
  let prev = null
  let next = null

  const currentIndex = _.findIndex(apiMenu, item => item.url === props.active)
  if (currentIndex > 0) {
    prev = apiMenu[currentIndex - 1]
  }
  if (currentIndex < apiMenu.length) {
    next = apiMenu[currentIndex + 1]
  }

  return (
    <ApiDocFooter>
      {prev && (
        <Link to={prev.url} className="prev">
          <FiArrowLeft />
          {prev.title}
        </Link>
      )}
      {next && (
        <Link to={next.url} className="next">
          {next.title}
          <FiArrowRight />
        </Link>
      )}
    </ApiDocFooter>
  )
}
